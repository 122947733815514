import React from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { cams } from "./data.js";
import { Icon } from "leaflet";
const fl1 = new Icon({
  iconUrl: 'https://img.icons8.com/ios-filled/50/apple-camera.png',
  iconSize: [50, 50],
  iconAnchor: [0, 0],
  popupAnchor: [-3, -76]

})
const fl2 = new Icon({
  iconUrl: 'https://img.icons8.com/color/48/traffic-light.png',
  iconSize: [48, 48],
  iconAnchor: [0, 0],
  popupAnchor: [-3, -76]
})
const fl3 = new Icon({
  iconUrl: 'https://img.icons8.com/color/48/traffic-light.png',
  iconSize: [48, 48],
  iconAnchor: [0, 0],
  popupAnchor: [-3, -76]
})

const getIcon = (id) => {
  if (id === 1) {
    return fl1;
  } else if (id === 2) {
    return fl2;
  } else {
    return fl3;
  }
}

function Map() {
  return (
    <MapContainer center={[42.724, 25.49]} zoom={10} scrollWheelZoom={true}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {cams.map(element => {
        return (
          <Marker icon={getIcon(element.flg)} position={[element.lat, element.lon]}>
            <Popup>
              {element.str} <br /> {element.spd}
            </Popup>
          </Marker>
        )

      })}
    </MapContainer>
  )
}
export default Map;
