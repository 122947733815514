export const cams = [
    {
      lat: 44.228767,
      lon: 28.176538,
      str: "A2",
      flg: 1,
      spd: 130,
      dir: [
        300
      ]
    },
    {
      lat: 44.167221,
      lon: 28.333611,
      str: "A2",
      flg: 1,
      spd: 130,
      dir: [
        140
      ]
    },
    {
      lat: 43.227948999999995,
      lon: 27.902503,
      str: "bulevard Tsar Osvoboditel",
      flg: 3,
      spd: 50,
      dir: [
        129,
        321
      ]
    },
    {
      lat: 43.219458333333336,
      lon: 27.888080000000002,
      str: "bulevard Hristo Smirnenski",
      flg: 3,
      spd: 50,
      dir: [
        144,
        238,
        325
      ]
    },
    {
      lat: 43.213432,
      lon: 27.894632,
      str: "ulitsa Knyaz Cherkaski",
      flg: 1,
      spd: 50,
      dir: [
        214
      ]
    },
    {
      lat: 41.558327,
      lon: 27.796167,
      str: "Atatürk Caddesi",
      flg: 1,
      spd: 90,
      dir: [
        310
      ]
    },
    {
      lat: 44.325134,
      lon: 28.030214,
      str: "A2",
      flg: 1,
      spd: 130,
      dir: [
        198
      ]
    },
    {
      lat: 43.22192866666666,
      lon: 27.874581000000003,
      str: "2",
      flg: 3,
      spd: 50,
      dir: [
        107,
        183,
        286
      ]
    },
    {
      lat: 43.031799,
      lon: 25.082474,
      str: "44",
      flg: 1,
      spd: 50,
      dir: [
        295
      ]
    },
    {
      lat: 43.209064,
      lon: 27.90435,
      str: "D-r Piskyuliev",
      flg: 3,
      spd: 50,
      dir: [
        295
      ]
    },
    {
      lat: 43.21257275000001,
      lon: 27.904027,
      str: "ulitsa Otets Paisiy",
      flg: 3,
      spd: 50,
      dir: [
        67,
        136,
        246,
        316
      ]
    },
    {
      lat: 43.2039905,
      lon: 27.9043745,
      str: "bul. Hristo Botev",
      flg: 3,
      spd: 50,
      dir: [
        50,
        233
      ]
    },
    {
      lat: 43.228489,
      lon: 27.906382,
      str: "ulitsa Prilep",
      flg: 1,
      spd: 50,
      dir: [
        335
      ]
    },
    {
      lat: 43.219064,
      lon: 27.934445,
      str: "bulevard 8-mi Primorski Polk",
      flg: 3,
      spd: 50,
      dir: [
        53,
        100,
        259
      ]
    },
    {
      lat: 43.216724,
      lon: 27.930882,
      str: "bulevard 8-mi Primorski Polk",
      flg: 3,
      spd: 50,
      dir: [
        49
      ]
    },
    {
      lat: 43.057186,
      lon: 27.860703,
      str: "9",
      flg: 1,
      spd: 60,
      dir: [
        187
      ]
    },
    {
      lat: 42.700909,
      lon: 27.648197,
      str: "9061",
      flg: 1,
      spd: 90,
      dir: [
        272
      ]
    },
    {
      lat: 43.204618,
      lon: 27.90835,
      str: "bul. Saborni",
      flg: 3,
      spd: 50,
      dir: [
        8,
        189,
        270
      ]
    },
    {
      lat: 43.206314,
      lon: 27.914714,
      str: "9",
      flg: 3,
      spd: 50,
      dir: [
        51,
        210
      ]
    },
    {
      lat: 43.212601,
      lon: 27.924335499999998,
      str: "9",
      flg: 3,
      spd: 50,
      dir: [
        45,
        225
      ]
    },
    {
      lat: 43.207288500000004,
      lon: 27.917285,
      str: "9",
      flg: 3,
      spd: 50,
      dir: [
        46,
        226
      ]
    },
    {
      lat: 43.204984,
      lon: 27.91071633333333,
      str: "9",
      flg: 3,
      spd: 50,
      dir: [
        98,
        187,
        280
      ]
    },
    {
      lat: 43.208214,
      lon: 27.92325666666667,
      str: "bulevard Knyaz Boris I",
      flg: 3,
      spd: 50,
      dir: [
        135,
        280,
        315
      ]
    },
    {
      lat: 42.691212,
      lon: 27.676893,
      str: "9061",
      flg: 1,
      spd: 60,
      dir: [
        315
      ]
    },
    {
      lat: 41.83485,
      lon: 27.792717,
      str: "D565",
      flg: 1,
      spd: 50,
      dir: [
        55
      ]
    },
    {
      lat: 42.161018,
      lon: 27.833319,
      str: "II-99",
      flg: 1,
      spd: 50,
      dir: [
        30
      ]
    },
    {
      lat: 41.822025,
      lon: 27.76063,
      str: "İğneada Caddesi",
      flg: 1,
      spd: 50,
      dir: [
        107
      ]
    },
    {
      lat: 42.064053,
      lon: 24.817831,
      str: "86",
      flg: 1,
      spd: 90,
      dir: [
        145
      ]
    },
    {
      lat: 42.16684,
      lon: 24.74272,
      str: "64",
      flg: 1,
      spd: 50,
      dir: [
        184
      ]
    },
    {
      lat: 42.4912815,
      lon: 27.471798999999997,
      str: "bulevard Ivan Vazov",
      flg: 2,
      spd: null,
      dir: [
        82,
        269
      ]
    },
    {
      lat: 42.502937,
      lon: 27.470024,
      str: "bulevard San Stefano",
      flg: 2,
      spd: null,
      dir: [
        104
      ]
    },
    {
      lat: 42.656837,
      lon: 27.680918,
      str: "9",
      flg: 1,
      spd: 90,
      dir: [
        65
      ]
    },
    {
      lat: 42.655018,
      lon: 27.675652499999998,
      str: "9",
      flg: 1,
      spd: 80,
      dir: [
        65,
        245
      ]
    },
    {
      lat: 42.694279,
      lon: 27.701429,
      str: "9",
      flg: 1,
      spd: 50,
      dir: [
        161
      ]
    },
    {
      lat: 42.674377,
      lon: 27.697145,
      str: "9",
      flg: 1,
      spd: 70,
      dir: [
        164
      ]
    },
    {
      lat: 42.826366,
      lon: 27.876808,
      str: "9",
      flg: 1,
      spd: 50,
      dir: [
        182
      ]
    },
    {
      lat: 42.713142,
      lon: 27.746197,
      str: "първа",
      flg: 1,
      spd: 50,
      dir: [
        276
      ]
    },
    {
      lat: 42.711136,
      lon: 27.734114,
      str: "Икцаряни",
      flg: 1,
      spd: 50,
      dir: [
        50
      ]
    },
    {
      lat: 42.564297,
      lon: 27.601883,
      str: "unknown",
      flg: 1,
      spd: 50,
      dir: [
        109
      ]
    },
    {
      lat: 42.469795,
      lon: 27.424047,
      str: "bulevard Zahari Stoianov",
      flg: 1,
      spd: 60,
      dir: [
        210
      ]
    },
    {
      lat: 42.712925,
      lon: 27.254399,
      str: "208",
      flg: 1,
      spd: 50,
      dir: [
        178
      ]
    },
    {
      lat: 42.51055399999999,
      lon: 27.467251,
      str: "bulevard Demokratsia",
      flg: 2,
      spd: null,
      dir: [
        129,
        289
      ]
    },
    {
      lat: 42.506794,
      lon: 27.468769,
      str: "Stefan Stambolov",
      flg: 2,
      spd: null,
      dir: [
        195
      ]
    },
    {
      lat: 42.506996,
      lon: 27.471426,
      str: "Demokratsia",
      flg: 2,
      spd: null,
      dir: [
        337
      ]
    },
    {
      lat: 42.467579,
      lon: 27.420008,
      str: "Georgi Popayanov",
      flg: 2,
      spd: null,
      dir: [
        283
      ]
    },
    {
      lat: 43.438873,
      lon: 24.594654,
      str: "Okolovrasten pat",
      flg: 1,
      spd: 90,
      dir: [
        295
      ]
    },
    {
      lat: 42.143059,
      lon: 24.74658,
      str: "ulitsa Gladston",
      flg: 1,
      spd: 40,
      dir: [
        91
      ]
    },
    {
      lat: 42.143791,
      lon: 24.752028,
      str: "boulevard Knyaginya Maria Luiza",
      flg: 1,
      spd: 40,
      dir: [
        285
      ]
    },
    {
      lat: 43.49873,
      lon: 24.548452,
      str: "118",
      flg: 1,
      spd: 50,
      dir: [
        25
      ]
    },
    {
      lat: 42.206873,
      lon: 24.732802,
      str: "64",
      flg: 1,
      spd: 60,
      dir: [
        14,
        193
      ]
    },
    {
      lat: 43.047646,
      lon: 24.887861,
      str: "4",
      flg: 1,
      spd: 60,
      dir: [
        109
      ]
    },
    {
      lat: 42.210537,
      lon: 24.782043,
      str: "Автомагистрала Тракия",
      flg: 1,
      spd: 140,
      dir: [
        88
      ]
    },
    {
      lat: 43.404644000000005,
      lon: 24.61759,
      str: "Stoyan Zaimov",
      flg: 2,
      spd: null,
      dir: [
        44,
        256
      ]
    },
    {
      lat: 41.723346500000005,
      lon: 27.217418000000002,
      str: "D555",
      flg: 1,
      spd: 70,
      dir: [
        160,
        340
      ]
    },
    {
      lat: 43.41816325,
      lon: 24.62304375,
      str: "Georgi Kochev",
      flg: 3,
      spd: 50,
      dir: [
        93,
        193,
        273,
        330
      ]
    },
    {
      lat: 43.411925249999996,
      lon: 24.6274145,
      str: "bul. Ruse",
      flg: 2,
      spd: null,
      dir: [
        28,
        85,
        261,
        273
      ]
    },
    {
      lat: 43.40514949999999,
      lon: 24.620615,
      str: "Stoyan Zaimov",
      flg: 2,
      spd: null,
      dir: [
        91,
        198
      ]
    },
    {
      lat: 43.41771525,
      lon: 24.615381749999997,
      str: "Grenaderska",
      flg: 2,
      spd: null,
      dir: [
        94,
        186,
        274,
        349
      ]
    },
    {
      lat: 43.41068575,
      lon: 24.61944075,
      str: "Dimitar Konstantinov",
      flg: 2,
      spd: null,
      dir: [
        29,
        144,
        209,
        313
      ]
    },
    {
      lat: 43.412155,
      lon: 24.614672,
      str: "bul. Doyran",
      flg: 2,
      spd: null,
      dir: [
        144,
        324
      ]
    },
    {
      lat: 42.132866,
      lon: 24.671099,
      str: "375",
      flg: 1,
      spd: 60,
      dir: [
        84
      ]
    },
    {
      lat: 43.41200550000001,
      lon: 24.636375,
      str: "bul. Ruse",
      flg: 2,
      spd: null,
      dir: [
        61,
        106,
        240,
        324
      ]
    },
    {
      lat: 42.4935,
      lon: 27.4503795,
      str: "Тодор Александров",
      flg: 2,
      spd: null,
      dir: [
        48,
        226
      ]
    },
    {
      lat: 42.491535,
      lon: 27.461428,
      str: "ulitsa Industrialna",
      flg: 2,
      spd: null,
      dir: null
    },
    {
      lat: 42.138718,
      lon: 24.772907,
      str: "bul. Sankt Peterburg",
      flg: 1,
      spd: 50,
      dir: [
        245
      ]
    },
    {
      lat: 43.418152,
      lon: 24.608763,
      str: "bul. Vasil Levski",
      flg: 2,
      spd: null,
      dir: [
        108
      ]
    },
    {
      lat: 42.555679,
      lon: 27.427395,
      str: "6",
      flg: 1,
      spd: 80,
      dir: [
        150
      ]
    },
    {
      lat: 42.51302,
      lon: 27.456135,
      str: "Yanko Komitov",
      flg: 2,
      spd: null,
      dir: [
        147,
        327
      ]
    },
    {
      lat: 42.512848,
      lon: 27.464745,
      str: "Stefan Stambolov",
      flg: 2,
      spd: null,
      dir: [
        331
      ]
    },
    {
      lat: 42.198368,
      lon: 24.637665,
      str: "A1",
      flg: 1,
      spd: 140,
      dir: [
        272
      ]
    },
    {
      lat: 43.040363,
      lon: 24.529268,
      str: "4",
      flg: 1,
      spd: 50,
      dir: [
        86
      ]
    },
    {
      lat: 42.443638,
      lon: 27.586317,
      str: "99",
      flg: 1,
      spd: 60,
      dir: [
        267
      ]
    },
    {
      lat: 41.7443905,
      lon: 27.239563,
      str: "D555",
      flg: 1,
      spd: 70,
      dir: [
        55,
        235
      ]
    },
    {
      lat: 42.0695,
      lon: 24.481499,
      str: "III-866",
      flg: 1,
      spd: 60,
      dir: [
        50
      ]
    },
    {
      lat: 41.7339745,
      lon: 24.423563,
      str: "866",
      flg: 1,
      spd: 50,
      dir: [
        111,
        184
      ]
    },
    {
      lat: 41.715698,
      lon: 27.19322,
      str: "Eski Edirne Kırklareli Yolu",
      flg: 1,
      spd: 90,
      dir: [
        80
      ]
    },
    {
      lat: 41.7291795,
      lon: 27.2396185,
      str: "D020",
      flg: 1,
      spd: 70,
      dir: [
        153,
        333
      ]
    },
    {
      lat: 43.190388,
      lon: 27.003332,
      str: "73",
      flg: 1,
      spd: 90,
      dir: [
        8
      ]
    },
    {
      lat: 42.486744,
      lon: 27.442991,
      str: "Todor Alexandrov blvd",
      flg: 1,
      spd: 80,
      dir: [
        40
      ]
    },
    {
      lat: 43.686901,
      lon: 26.805901,
      str: "205",
      flg: 1,
      spd: 90,
      dir: [
        338
      ]
    },
    {
      lat: 42.671333,
      lon: 23.994797,
      str: "6",
      flg: 1,
      spd: 60,
      dir: [
        251
      ]
    },
    {
      lat: 42.154148,
      lon: 26.706329,
      str: "79",
      flg: 1,
      spd: 50,
      dir: [
        133
      ]
    },
    {
      lat: 43.3619,
      lon: 26.851397,
      str: "Васил Левски",
      flg: 1,
      spd: 50,
      dir: [
        320
      ]
    },
    {
      lat: 43.315502,
      lon: 26.923119,
      str: "2",
      flg: 1,
      spd: 60,
      dir: [
        315
      ]
    },
    {
      lat: 42.1642,
      lon: 24.211609,
      str: "84",
      flg: 1,
      spd: 50,
      dir: [
        98
      ]
    },
    {
      lat: 42.278015,
      lon: 24.277576,
      str: "A1",
      flg: 1,
      spd: 140,
      dir: [
        300
      ]
    },
    {
      lat: 42.431736,
      lon: 23.852234,
      str: "Автомагистрала Тракия",
      flg: 1,
      spd: 130,
      dir: [
        197
      ]
    },
    {
      lat: 41.68763,
      lon: 26.588852,
      str: "D535",
      flg: 1,
      spd: 50,
      dir: [
        55
      ]
    },
    {
      lat: 43.219875,
      lon: 26.65686,
      str: "74",
      flg: 1,
      spd: 70,
      dir: [
        305
      ]
    },
    {
      lat: 42.69445,
      lon: 24.075241,
      str: "6",
      flg: 1,
      spd: 50,
      dir: [
        55
      ]
    },
    {
      lat: 41.663246,
      lon: 26.577326,
      str: "Talat Paşa Caddesi",
      flg: 1,
      spd: 50,
      dir: [
        134
      ]
    },
    {
      lat: 41.681973,
      lon: 26.583412,
      str: "Lalapaşa Yolu",
      flg: 1,
      spd: 50,
      dir: [
        264
      ]
    },
    {
      lat: 44.307102,
      lon: 23.833755,
      str: "Bulevardul Decebal",
      flg: 1,
      spd: 50,
      dir: [
        170
      ]
    },
    {
      lat: 41.676914,
      lon: 26.552847,
      str: "Talat Paşa Caddesi",
      flg: 2,
      spd: null,
      dir: null
    },
    {
      lat: 41.676422,
      lon: 26.556669,
      str: "Talat Paşa Caddesi",
      flg: 2,
      spd: null,
      dir: null
    },
    {
      lat: 43.504875,
      lon: 26.600145,
      str: "9-ти Септември",
      flg: 1,
      spd: 50,
      dir: [
        300
      ]
    },
    {
      lat: 41.587059,
      lon: 23.742882,
      str: "ул. Панаирски ливади",
      flg: 1,
      spd: 50,
      dir: [
        12
      ]
    },
    {
      lat: 41.693974,
      lon: 26.44293,
      str: "Edirne - Kapıkule Yolu",
      flg: 1,
      spd: 110,
      dir: [
        300
      ]
    },
    {
      lat: 42.12571,
      lon: 26.537659,
      str: "7",
      flg: 1,
      spd: 50,
      dir: [
        45
      ]
    },
    {
      lat: 42.879116,
      lon: 23.803646,
      str: "A2",
      flg: 1,
      spd: 140,
      dir: [
        225
      ]
    },
    {
      lat: 42.099369,
      lon: 26.543377,
      str: "7",
      flg: 1,
      spd: 90,
      dir: [
        306
      ]
    },
    {
      lat: 41.581905,
      lon: 23.895611,
      str: "197",
      flg: 1,
      spd: 50,
      dir: [
        2
      ]
    },
    {
      lat: 43.015114,
      lon: 26.6138,
      str: "706",
      flg: 1,
      spd: 50,
      dir: [
        98
      ]
    },
    {
      lat: 41.6340005,
      lon: 26.6135845,
      str: "İstanbul - Kapıkule Yolu",
      flg: 1,
      spd: 50,
      dir: [
        135,
        315
      ]
    },
    {
      lat: 41.6367835,
      lon: 26.610092,
      str: "İstanbul - Kapıkule Yolu",
      flg: 1,
      spd: 30,
      dir: [
        131,
        318
      ]
    },
    {
      lat: 41.677471,
      lon: 26.525026,
      str: "Edirne - Kapıkule Yolu",
      flg: 1,
      spd: 50,
      dir: [
        90
      ]
    },
    {
      lat: 42.831642,
      lon: 23.7966635,
      str: "Автомагистрала Хемус",
      flg: 1,
      spd: 80,
      dir: [
        0,
        180
      ]
    },
    {
      lat: 43.174252,
      lon: 23.608839,
      str: "E79",
      flg: 1,
      spd: 90,
      dir: [
        120
      ]
    },
    {
      lat: 43.783188,
      lon: 23.687721,
      str: "11",
      flg: 1,
      spd: 90,
      dir: [
        81
      ]
    },
    {
      lat: 42.698539499999995,
      lon: 23.309727000000002,
      str: "gen. Skobelev Blvd.",
      flg: 2,
      spd: null,
      dir: [
        169,
        349
      ]
    },
    {
      lat: 42.687029,
      lon: 23.3103925,
      str: "bulevard Pencho Slaveykov",
      flg: 3,
      spd: 50,
      dir: [
        133,
        313
      ]
    },
    {
      lat: 42.685287,
      lon: 23.296852,
      str: "бул. Цар Борис III",
      flg: 2,
      spd: null,
      dir: [
        36
      ]
    },
    {
      lat: 42.678886,
      lon: 23.343506,
      str: "bulevard Peyo K. Yavorov",
      flg: 1,
      spd: 70,
      dir: [
        45
      ]
    },
    {
      lat: 42.497849,
      lon: 25.993544,
      str: "E773",
      flg: 1,
      spd: 50,
      dir: [
        275
      ]
    },
    {
      lat: 42.684147,
      lon: 23.346769,
      str: "Tsarigradsko Shose blvd.",
      flg: 1,
      spd: 80,
      dir: [
        320
      ]
    },
    {
      lat: 42.69315,
      lon: 23.354855,
      str: "Оборище",
      flg: 2,
      spd: null,
      dir: [
        91
      ]
    },
    {
      lat: 42.690605,
      lon: 23.35371,
      str: "Попова шапка",
      flg: 2,
      spd: null,
      dir: [
        76
      ]
    },
    {
      lat: 42.696815,
      lon: 23.354198,
      str: "Madrid Blvd",
      flg: 2,
      spd: null,
      dir: [
        94
      ]
    },
    {
      lat: 42.688896,
      lon: 23.339933,
      str: "bulevard Tsarigradsko Shose",
      flg: 1,
      spd: 50,
      dir: [
        317
      ]
    },
    {
      lat: 42.690574,
      lon: 23.337533500000003,
      str: "bulevard Tsar Osvoboditel",
      flg: 3,
      spd: 50,
      dir: [
        50,
        131,
        255,
        310
      ]
    },
    {
      lat: 42.69553075,
      lon: 23.334996250000003,
      str: "bulevard Vasil Levski",
      flg: 3,
      spd: 50,
      dir: [
        82,
        169,
        262,
        349
      ]
    },
    {
      lat: 42.694019,
      lon: 23.346693,
      str: "bulevard Evlogi i Hristo Georgiev",
      flg: 3,
      spd: 50,
      dir: [
        351
      ]
    },
    {
      lat: 42.6856765,
      lon: 23.350424750000002,
      str: "bulevard Mihai Eminescu",
      flg: 3,
      spd: 50,
      dir: [
        50,
        127,
        227,
        314
      ]
    },
    {
      lat: 43.223064,
      lon: 23.559528,
      str: "Mito Orozov",
      flg: 1,
      spd: 50,
      dir: [
        16
      ]
    },
    {
      lat: 43.777313,
      lon: 26.019863,
      str: "2",
      flg: 1,
      spd: 60,
      dir: [
        310
      ]
    },
    {
      lat: 42.672611,
      lon: 23.336095,
      str: "bulevard Peyo K. Yavorov",
      flg: 2,
      spd: null,
      dir: [
        140,
        320
      ]
    },
    {
      lat: 41.649723,
      lon: 26.45647,
      str: "Ethniki Odos Kastanion Ormeniou",
      flg: 1,
      spd: 100,
      dir: [
        330
      ]
    },
    {
      lat: 42.650856,
      lon: 23.289808,
      str: "E871",
      flg: 1,
      spd: 80,
      dir: [
        154
      ]
    },
    {
      lat: 42.665297333333335,
      lon: 23.28774,
      str: "Boulevard Bulgaria",
      flg: 2,
      spd: null,
      dir: [
        39,
        144,
        236
      ]
    },
    {
      lat: 42.475651,
      lon: 25.874125,
      str: "66",
      flg: 1,
      spd: 50,
      dir: [
        272
      ]
    },
    {
      lat: 41.724461,
      lon: 26.326397,
      str: "Maritsa Motorway",
      flg: 1,
      spd: 60,
      dir: [
        300
      ]
    },
    {
      lat: 42.631725,
      lon: 23.459442,
      str: "Trakia Motorway",
      flg: 1,
      spd: 80,
      dir: [
        260
      ]
    },
    {
      lat: 42.663336,
      lon: 23.35747775,
      str: "bulevard Dragan Tsankov",
      flg: 2,
      spd: null,
      dir: [
        43,
        119,
        223,
        299
      ]
    },
    {
      lat: 42.66312,
      lon: 23.291916,
      str: "Todor Kableshkov",
      flg: 2,
      spd: null,
      dir: [
        140
      ]
    },
    {
      lat: 42.464527,
      lon: 26.069742,
      str: "A1",
      flg: 1,
      spd: 140,
      dir: [
        235
      ]
    },
    {
      lat: 43.395466,
      lon: 23.240458,
      str: "ulitsa Diana",
      flg: 1,
      spd: 60,
      dir: [
        178
      ]
    },
    {
      lat: 42.733589,
      lon: 23.250013,
      str: "Slivnitsa Blvd.",
      flg: 2,
      spd: null,
      dir: [
        333
      ]
    },
    {
      lat: 42.6986065,
      lon: 23.3345975,
      str: "bulevard Kniaz Aleksandar Dondukov",
      flg: 3,
      spd: 50,
      dir: [
        10,
        95
      ]
    },
    {
      lat: 42.704769,
      lon: 23.32902,
      str: "bulevard Slivnitsa",
      flg: 2,
      spd: null,
      dir: [
        269
      ]
    },
    {
      lat: 43.25444,
      lon: 26.557775,
      str: "E772",
      flg: 1,
      spd: 60,
      dir: [
        220
      ]
    },
    {
      lat: 42.676449,
      lon: 23.333557,
      str: "bulevard Hristo Smirnenski",
      flg: 2,
      spd: null,
      dir: [
        115
      ]
    },
    {
      lat: 42.67849,
      lon: 23.321766,
      str: "bulevard Arsenalski 5",
      flg: 3,
      spd: 50,
      dir: null
    },
    {
      lat: 42.356281,
      lon: 25.657145,
      str: "E85",
      flg: 1,
      spd: 60,
      dir: [
        12
      ]
    },
    {
      lat: 42.600753999999995,
      lon: 23.1196,
      str: "бул. Скоростна магистрала",
      flg: 1,
      spd: 80,
      dir: [
        89,
        270
      ]
    },
    {
      lat: 43.414089,
      lon: 23.205196,
      str: "E79",
      flg: 1,
      spd: 50,
      dir: [
        74
      ]
    },
    {
      lat: 42.702400499999996,
      lon: 23.338388,
      str: "bul. General Danail Nikolaev",
      flg: 1,
      spd: 50,
      dir: [
        125,
        305
      ]
    },
    {
      lat: 42.700451,
      lon: 23.342295,
      str: "bul. General Danail Nikolaev",
      flg: 2,
      spd: null,
      dir: [
        6
      ]
    },
    {
      lat: 42.776173,
      lon: 23.1809825,
      str: "bul. European Road",
      flg: 1,
      spd: 50,
      dir: [
        146,
        326
      ]
    },
    {
      lat: 42.648107499999995,
      lon: 23.37693525,
      str: "bulevard Aleksandar Malinov",
      flg: 3,
      spd: 50,
      dir: [
        25,
        73,
        205,
        253
      ]
    },
    {
      lat: 42.646976,
      lon: 23.376167,
      str: "ulitsa Filip Avramo",
      flg: 3,
      spd: 50,
      dir: [
        241
      ]
    },
    {
      lat: 42.64974,
      lon: 23.37797425,
      str: "ulitsa Andrey Saharov",
      flg: 3,
      spd: 50,
      dir: [
        25,
        154,
        213,
        333
      ]
    },
    {
      lat: 43.1850945,
      lon: 23.58564,
      str: "bul. 2-ri Yuni",
      flg: 1,
      spd: 50,
      dir: [
        120,
        300
      ]
    },
    {
      lat: 42.625523,
      lon: 23.130764,
      str: "A6 Avtomagisrala Struma",
      flg: 1,
      spd: 80,
      dir: [
        125
      ]
    },
    {
      lat: 42.6520275,
      lon: 23.379429000000002,
      str: "bul. Aleksandar Malinov",
      flg: 2,
      spd: null,
      dir: [
        25,
        205
      ]
    },
    {
      lat: 42.67933000000001,
      lon: 23.30248425,
      str: "Boulevard Bulgaria",
      flg: 3,
      spd: 50,
      dir: [
        12,
        104,
        154,
        284
      ]
    },
    {
      lat: 42.688871500000005,
      lon: 23.3191215,
      str: "bulevard Patriarh Evtimiy",
      flg: 2,
      spd: null,
      dir: [
        263,
        346
      ]
    },
    {
      lat: 42.688553750000004,
      lon: 23.334012750000003,
      str: "bulevard Evlogi i Hristo Georgiev",
      flg: 3,
      spd: 50,
      dir: [
        51,
        131,
        231,
        310
      ]
    },
    {
      lat: 42.6974145,
      lon: 23.324252,
      str: "Nezavisimost Sq.",
      flg: 2,
      spd: null,
      dir: [
        278,
        295
      ]
    },
    {
      lat: 42.684008000000006,
      lon: 23.323822,
      str: "Evlogi and Hristo Georgiev blvd.",
      flg: 2,
      spd: null,
      dir: [
        24,
        204
      ]
    },
    {
      lat: 42.69309825,
      lon: 23.334314,
      str: "ulitsa Tsar Osvoboditel",
      flg: 3,
      spd: 50,
      dir: [
        116,
        217,
        349
      ]
    },
    {
      lat: 42.697685500000006,
      lon: 23.315304,
      str: "bulevard Hristo Botev",
      flg: 3,
      spd: 50,
      dir: [
        79,
        259
      ]
    },
    {
      lat: 42.6861,
      lon: 23.330730000000003,
      str: "bulevard Evlogi i Hristo Georgiev",
      flg: 3,
      spd: 50,
      dir: [
        84,
        223,
        297
      ]
    },
    {
      lat: 42.69772524999999,
      lon: 23.32173975,
      str: "bulevard Knyaginya Maria Luiza",
      flg: 3,
      spd: 50,
      dir: [
        72,
        101,
        169,
        258
      ]
    },
    {
      lat: 42.6886235,
      lon: 23.3231405,
      str: "ulitsa Georgi S. Rakovski",
      flg: 3,
      spd: 50,
      dir: [
        173,
        353
      ]
    },
    {
      lat: 42.688139,
      lon: 23.328863,
      str: "bulevard Vasil Levski",
      flg: 3,
      spd: 50,
      dir: [
        55,
        211
      ]
    },
    {
      lat: 42.6951855,
      lon: 23.329354249999998,
      str: "ulitsa Georgi S. Rakovski",
      flg: 3,
      spd: 50,
      dir: [
        61,
        151,
        241,
        331
      ]
    },
    {
      lat: 42.690216,
      lon: 23.331432,
      str: "ulitsa General Yosif V. Gourko",
      flg: 3,
      spd: 50,
      dir: [
        232
      ]
    },
    {
      lat: 42.682564,
      lon: 23.320335,
      str: "bulevard Evlogi i Hristo Georgiev",
      flg: 2,
      spd: null,
      dir: [
        61
      ]
    },
    {
      lat: 42.698099,
      lon: 23.329772,
      str: "ulitsa Georgi S. Rakovski",
      flg: 3,
      spd: 50,
      dir: [
        7,
        96
      ]
    },
    {
      lat: 42.683441,
      lon: 23.321053,
      str: "bulevard Evlogi i Hristo Georgiev",
      flg: 2,
      spd: null,
      dir: [
        245
      ]
    },
    {
      lat: 42.696873,
      lon: 23.320543,
      str: "bulevard Aleksandar Stamboliyski",
      flg: 3,
      spd: 30,
      dir: [
        97
      ]
    },
    {
      lat: 42.6595905,
      lon: 23.34775125,
      str: "ulitsa Nikola Gabrovski",
      flg: 3,
      spd: 50,
      dir: [
        29,
        118,
        209,
        299
      ]
    },
    {
      lat: 44.016075,
      lon: 22.871405,
      str: "Ring Vidin",
      flg: 1,
      spd: 70,
      dir: [
        277
      ]
    },
    {
      lat: 43.641125,
      lon: 25.872835,
      str: "5",
      flg: 1,
      spd: 90,
      dir: [
        174
      ]
    },
    {
      lat: 42.80545,
      lon: 23.220755,
      str: "81",
      flg: 1,
      spd: 50,
      dir: [
        329
      ]
    },
    {
      lat: 42.699909,
      lon: 23.310514,
      str: "ulitsa Sredna Gora",
      flg: 3,
      spd: 50,
      dir: [
        257
      ]
    },
    {
      lat: 42.70599,
      lon: 23.312426,
      str: "bulevard Slivnitsa",
      flg: 3,
      spd: 50,
      dir: [
        265
      ]
    },
    {
      lat: 42.7019155,
      lon: 23.2979345,
      str: "bul. Todor Aleksandrov",
      flg: 2,
      spd: null,
      dir: [
        257,
        348
      ]
    },
    {
      lat: 43.813358,
      lon: 25.981852,
      str: "E70",
      flg: 1,
      spd: 80,
      dir: [
        354
      ]
    },
    {
      lat: 43.841343,
      lon: 25.975906,
      str: "2",
      flg: 1,
      spd: 50,
      dir: [
        65
      ]
    },
    {
      lat: 41.720585,
      lon: 23.160654,
      str: "1",
      flg: 1,
      spd: 30,
      dir: [
        152
      ]
    },
    {
      lat: 42.274055,
      lon: 22.994747,
      str: "62",
      flg: 1,
      spd: 90,
      dir: [
        282
      ]
    },
    {
      lat: 43.158577,
      lon: 25.881092,
      str: "4",
      flg: 1,
      spd: 60,
      dir: [
        300
      ]
    },
    {
      lat: 44.022167,
      lon: 22.925045,
      str: "Скоростен път „Видин – Ботевград“",
      flg: 1,
      spd: 90,
      dir: [
        283
      ]
    },
    {
      lat: 42.385193,
      lon: 25.648899,
      str: "5",
      flg: 1,
      spd: 50,
      dir: [
        12
      ]
    },
    {
      lat: 43.371655,
      lon: 25.644394,
      str: "5",
      flg: 1,
      spd: 60,
      dir: [
        178
      ]
    },
    {
      lat: 43.33765,
      lon: 25.63706,
      str: "E85",
      flg: 1,
      spd: 50,
      dir: [
        187
      ]
    },
    {
      lat: 42.431721,
      lon: 25.683725,
      str: "66",
      flg: 1,
      spd: 50,
      dir: [
        255
      ]
    },
    {
      lat: 42.274693,
      lon: 22.757418,
      str: "62",
      flg: 1,
      spd: 50,
      dir: [
        255
      ]
    },
    {
      lat: 43.075214,
      lon: 25.682161,
      str: "4",
      flg: 1,
      spd: 60,
      dir: [
        60
      ]
    }
  ]